import { useState, useEffect } from "react";
import { loadStripe, StripeCardElementChangeEvent } from "@stripe/stripe-js";
import {
  Elements,
  useStripe,
  useElements,
  CardElement,
} from "@stripe/react-stripe-js";
import Button from "components/Button";
import { Order } from "data/Order";
import { config } from "config";
import OrderDetails from "components/OrderDetails";
import FormItem from "components/FormItem";
import { useForm } from "react-hook-form";

const stripePromise = fetch("/api/stripe_info", {
  headers: { "X-API-Key": config.apiKey },
})
  .then((res) => res.json())
  .then((data) => loadStripe(data.publishableKey));

const Payment = ({
  order,
  pressedBack,
  completedPayment,
}: {
  order: Order;
  pressedBack: () => void;
  completedPayment: () => void;
}) => {
  return (
    <Elements
      stripe={stripePromise}
      options={{
        locale: "fi",
        fonts: [
          {
            cssSrc:
              "https://fonts.googleapis.com/css2?family=Epilogue:wght@300&display=swap",
          },
        ],
      }}
    >
      <h1 className="snapi-title-lg mb-11">Tarkista ja maksa</h1>
      <OrderDetails order={order} senderTitle="Mistä" receiverTitle="Minne" />

      <CheckoutForm
        order={order}
        pressedBack={pressedBack}
        completedPayment={completedPayment}
      ></CheckoutForm>
    </Elements>
  );
};

function CheckoutForm({
  order,
  pressedBack,
  completedPayment,
}: {
  order: Order;
  pressedBack: () => void;
  completedPayment: () => void;
}) {
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [processing, setProcessing] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState<string | undefined>();
  const stripe = useStripe();
  const elements = useElements();
  const { register, errors, handleSubmit } = useForm<{ email: string }>();
  useEffect(() => {
    setClientSecret(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!stripe || !elements) {
    return null;
  }

  const cardStyle = {
    style: {
      base: {
        color: "#000000",
        fontFamily: "Epilogue",
        fontWeight: "300",
        fontSize: "16px",
        "::placeholder": {
          color: "#cecece",
        },
      },
      invalid: {
        color: "#ef4444",
        iconColor: "#ef4444",
      },
    },
  };
  const handleChange = async (event: StripeCardElementChangeEvent) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };
  const onSubmit = async ({ email }: { email: string }) => {
    setProcessing(true);

    let theClientSecret: string;

    if (clientSecret === undefined) {
      try {
        const response = await fetch("/api/consumer_order", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-API-Key": config.apiKey,
          },
          body: JSON.stringify({ ...order, receiptEmail: email }),
        });

        const data = await response.json();

        setClientSecret(data.clientSecret);
        theClientSecret = data.clientSecret;
      } catch (e) {
        setProcessing(false);
        alert("Tilaus epäonnistui. Ole hyvä ja yritä uudelleen.");
        return;
      }
    } else {
      theClientSecret = clientSecret;
    }

    const payload = await stripe.confirmCardPayment(theClientSecret, {
      payment_method: {
        card: elements.getElement(CardElement)!,
      },
    });
    if (payload.error) {
      setError(`Maksu epäonnistui: ${payload.error.message}`);
      setProcessing(false);
    } else {
      setError(null);
      setProcessing(false);
      setSucceeded(true);
      completedPayment();
    }
  };
  return (
    <form id="payment-form" onSubmit={handleSubmit(onSubmit)}>
      <h2 className="snapi-title mb-4">Maksa kortilla</h2>
      <div className="mb-6">
        <CardElement
          id="card-element"
          className="snapi-input py-2.5 mb-1"
          options={cardStyle}
          onChange={handleChange}
        />
        {/* Show any error that happens when processing the payment */}
        {error && (
          <div className="snapi-input-error" role="alert">
            {error}
          </div>
        )}
      </div>

      <FormItem
        name="email"
        label="Maksajan sähköposti kuittia varten"
        className="col-span-2 mb-11"
        error={errors.email ? "Syötä kelvollinen sähköposti" : undefined}
        required={true}
        register={register}
        validate={(email) => /.+@.+\..+/.test(email)}
      />

      <div className="flex flex-col col-span-2 bg-gray-200 p-5 rounded-lg">
        <h2 className="snapi-title mb-4">Hinta</h2>
        <p className="snapi-body">Kuljetuksen hinta {order.price} € alv. 24%</p>
      </div>

      <div className="flex flex-col-reverse sm:flex-row justify-between items-center mt-11 mb-8 space-y-4 space-y-reverse sm:space-y-0 space-x-0 sm:space-x-8">
        <button
          onClick={pressedBack}
          type="button"
          className="snapi-button-normal w-full sm:w-auto"
        >
          Takaisin
        </button>
        <Button
          className="snapi-button w-full sm:w-auto"
          disabled={processing || disabled || succeeded}
          loading={processing}
          id="submit"
        >
          <span>Maksa ja tilaa</span>
        </Button>
      </div>
    </form>
  );
}

export default Payment;
