import Loader from "./Loader";

type ButtonProps = {
  id?: string;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  onClick?: () => void;
  children: JSX.Element | string;
};

const Button = (props: ButtonProps) => (
  <button
    id={props.id}
    onClick={props.onClick}
    className={["snapi-button", props.className]
      .filter((x) => x !== undefined)
      .join(" ")}
    disabled={props.disabled}
  >
    {props.loading && <Loader />}
    {!props.loading && props.children}
  </button>
);

export default Button;
