import React from "react";

import { assertNever } from "assertNever";
import EnterAddress from "./EnterAddress";
import EnterBusinessInfo from "./EnterBusinessInfo";
import EnterParcelInfo from "./EnterParcelInfo";
import OrderCompleted from "./OrderCompleted";
import {
  reducer,
  initialState,
  Step,
  completedEnterBusinessInfo,
  completedEnterSender,
  completedEnterReceiver,
  pressedBackInSender,
  pressedBackInReceiver,
  pressedBackInParcelInfo,
  completedEnterParcelInfo,
  pressedNewOrder,
  setPrice,
  pressedBackInConfirmOrder,
  completedConfirmOrder,
} from "order/businessState";
import DetailsSidebar from "./DetailsSidebar";
import Stepper from "components/Stepper";
import OrderLayout from "./OrderLayout";
import { toUndefined } from "@devexperts/remote-data-ts";
import { Order } from "data/Order";
import { delayed } from "delayed";
import ConfirmOrder from "./ConfirmOrder";
import { defaultParcel } from "data/Parcel";
import { OrderType } from "data/OrderType";
import { getCollectionTime } from "data/CollectionTimes";
import isBefore from "date-fns/isBefore";

function stepNumber(step: Step): number {
  switch (step) {
    case Step.EnterBusinessInfo:
      return 0;
    case Step.EnterSender:
      return 1;
    case Step.EnterReceiver:
      return 2;
    case Step.EnterParcelInfo:
      return 3;
    case Step.ConfirmOrder:
      return 4;
    case Step.OrderCompleted:
      return 5;
    default:
      assertNever(step);
  }
}

const BusinessOrderFlow = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [ordering, setOrdering] = React.useState(false);
  const { step, sender, receiver, price, orderType } = state;

  let content: JSX.Element;

  switch (step) {
    case Step.EnterBusinessInfo:
      content = (
        <EnterBusinessInfo
          pressedContinue={(info) => {
            dispatch(completedEnterBusinessInfo(info));
          }}
        />
      );
      break;
    case Step.EnterSender:
      content = (
        <EnterAddress
          key="sender"
          title="Noutopaikan tiedot"
          defaultValue={sender}
          pressedBack={(address) => {
            if (
              window.confirm(
                "Haluatko palata alkuun? Syötetyt tiedot menetetään."
              )
            ) {
              dispatch(pressedBackInSender(address));
            }
          }}
          backText="Palaa alkuun"
          backVisible={true}
          pressedContinue={(address) => {
            dispatch(completedEnterSender(address));
          }}
          showBusinessLink={false}
        />
      );
      break;
    case Step.EnterReceiver:
      content = (
        <EnterAddress
          key="receiver"
          title="Vastaanottajan tiedot"
          defaultValue={receiver}
          pressedBack={(address) => {
            dispatch(pressedBackInReceiver(address));
          }}
          backText="Takaisin"
          backVisible={true}
          pressedContinue={(address) => {
            dispatch(completedEnterReceiver(address));
          }}
          showBusinessLink={false}
        />
      );
      break;
    case Step.EnterParcelInfo:
      content = (
        <EnterParcelInfo
          sender={sender!}
          receiver={receiver!}
          businessInfo={state.businessInfo!}
          orderType={state.orderType}
          defaultValue={state.parcel ?? defaultParcel}
          price={state.price}
          setPrice={(p) => dispatch(setPrice(p))}
          pressedBack={(parcel) => {
            dispatch(pressedBackInParcelInfo(parcel));
          }}
          pressedContinue={(p) => {
            if (!sender || !receiver || !state.businessInfo) {
              return;
            }

            const order: Order = {
              date: p.date?.toISOString(),
              type: orderType,
              receiptEmail: state.businessInfo.email,
              sender: {
                name: sender.name,
                phone: sender.phone,
                address: {
                  streetAddress: sender.streetAddress,
                  postCode: sender.postCode,
                  city: sender.city,
                  country: sender.country,
                },
                instructions: p.messageToDriver,
              },
              receiver: {
                name: receiver.name,
                phone: receiver.phone,
                address: {
                  streetAddress: receiver.streetAddress,
                  postCode: receiver.postCode,
                  city: receiver.city,
                  country: receiver.country,
                },
                instructions: p.messageToDriver,
              },
              price: toUndefined(price)!.price,
              parcels: [
                {
                  dimensions: {
                    length: parseFloat(p.length.replace(",", ".")),
                    width: parseFloat(p.width.replace(",", ".")),
                    height: parseFloat(p.height.replace(",", ".")),
                  },
                  weight: parseFloat(p.weight.replace(",", ".")),
                },
              ],
              parcelCount: parseInt(p.parcelCount, 10),
            };

            dispatch(completedEnterParcelInfo(p, order));
          }}
        />
      );
      break;
    case Step.ConfirmOrder:
      content = (
        <ConfirmOrder
          order={state.order!}
          processing={ordering}
          pressedBack={() => dispatch(pressedBackInConfirmOrder())}
          pressedOrder={() => {
            const order = state.order!;

            if (state.orderType === OrderType.SnapiToday && order.date) {
              const collectionTime = getCollectionTime(
                state.businessInfo!.collectionTimes,
                new Date(order.date)
              );
              if (isBefore(collectionTime, new Date())) {
                window.alert(
                  "Paketti ei kerkeä tämän päivän keräilyyn. Vaihda noutopäivää jatkaaksesi."
                );
                return;
              }
            }

            setOrdering(true);

            delayed(
              fetch("/api/order", {
                method: "POST",
                body: JSON.stringify(order),
                headers: {
                  Authorization: `Basic ${btoa(
                    state.businessInfo!.accountIdentifier +
                      ":" +
                      state.businessInfo!.pin
                  )}`,
                },
              })
            )
              .then((res) => {
                setOrdering(false);
                if (res.ok) {
                  dispatch(completedConfirmOrder());
                } else {
                  window.alert("Tilaus epäonnistui.");
                }
              })
              .catch(() => {
                setOrdering(false);
                window.alert("Tilaus epäonnistui.");
              });
          }}
        />
      );
      break;
    case Step.OrderCompleted:
      content = (
        <OrderCompleted
          order={state.order!}
          senderTitle="Noutopaikan tiedot"
          receiverTitle="Vastaanottaja"
          pressedNewOrder={() => {
            dispatch(pressedNewOrder());
          }}
        />
      );
      break;
    default:
      assertNever(step);
  }

  let showSidebar: boolean;
  switch (step) {
    case Step.EnterBusinessInfo:
    case Step.EnterSender:
    case Step.EnterReceiver:
    case Step.EnterParcelInfo:
      showSidebar = true;
      break;
    case Step.ConfirmOrder:
    case Step.OrderCompleted:
      showSidebar = false;
      break;
  }

  return (
    <OrderLayout
      stepper={<Stepper className="mb-4" step={stepNumber(step)} size={6} />}
      content={content}
      sidebar={
        showSidebar ? (
          <DetailsSidebar
            senderTitle="Noutopaikan tiedot"
            receiverTitle="Vastaanottaja"
            sender={sender}
            receiver={receiver}
            showSender={stepNumber(step) > stepNumber(Step.EnterSender)}
            showReceiver={stepNumber(step) > stepNumber(Step.EnterReceiver)}
          />
        ) : null
      }
    />
  );
};

export default BusinessOrderFlow;
