export type Parcel = Readonly<{
  length: string;
  width: string;
  height: string;
  size: "S" | "M" | "L" | undefined;
  weight: string;
  date: Date | undefined;
  messageToDriver: string;
  parcelCount: string;
}>;

export const defaultParcel: Parcel = {
  length: "",
  width: "",
  height: "",
  size: undefined,
  weight: "",
  date: undefined,
  messageToDriver: "",
  parcelCount: "",
};
