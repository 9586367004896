import BusinessOrderFlow from "./order/BusinessOrderFlow";
import ConsumerOrderFlow from "./order/ConsumerOrderFlow";
import Start from "./order/Start";
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";
import { OrderRoute } from "order/OrderRoute";

fetch("/version.json", { headers: { "Cache-Control": "no-store" } })
  .then((data) => data.json())
  .then((data) => {
    console.log(`Snapi :: Running version ${data.version}`);
  })
  .catch(() => {});

function App() {
  return (
    <Router>
      <div className="max-w-screen-lg mx-auto p-4 sm:p-8">
        <Switch>
          <Route path={OrderRoute.Consumer} component={ConsumerOrderFlow} />
          <Route path={OrderRoute.Business} component={BusinessOrderFlow} />
          <Route
            path="/"
            render={({ history }) => (
              <Start
                pressedBusiness={() => {
                  history.push(OrderRoute.Business);
                }}
                pressedConsumer={() => {
                  history.push(OrderRoute.Consumer);
                }}
              />
            )}
          ></Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
