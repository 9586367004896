import { Address } from "data/Address";
import { BusinessInfo } from "data/BusinessInfo";
import { Parcel } from "data/Parcel";
import { createAction, createReducer } from "@reduxjs/toolkit";
import { RemoteData, initial } from "@devexperts/remote-data-ts";
import { Order } from "data/Order";
import { OrderType } from "data/OrderType";

export enum Step {
  EnterBusinessInfo = "EnterBusinessInfo",
  EnterSender = "EnterSender",
  EnterReceiver = "EnterReceiver",
  EnterParcelInfo = "EnterParcelInfo",
  ConfirmOrder = "ConfirmOrder",
  OrderCompleted = "OrderCompleted",
}

export const completedEnterBusinessInfo = createAction(
  "completedEnterBusinessInfo",
  (businessInfo: BusinessInfo & { orderType: OrderType }) => ({
    payload: businessInfo,
  })
);

export const completedEnterSender = createAction(
  "completedEnterSender",
  (address: Address) => ({
    payload: { address },
  })
);

export const completedEnterReceiver = createAction(
  "completedEnterReceiver",
  (address: Address) => ({
    payload: { address },
  })
);

export const pressedBackInSender = createAction(
  "pressedBackInSender",
  (address: Address) => ({ payload: address })
);

export const pressedBackInReceiver = createAction(
  "pressedBackInReceiver",
  (address: Address) => ({ payload: address })
);

export const pressedBackInParcelInfo = createAction(
  "pressedBackInParcelInfo",
  (parcel: Parcel) => ({ payload: parcel })
);

export const pressedBackInConfirmOrder = createAction(
  "pressedBackInConfirmOrder"
);

export const completedEnterParcelInfo = createAction(
  "completedEnterParcelInfo",
  (parcel: Parcel, order: Order) => ({ payload: { parcel, order } })
);

export const completedConfirmOrder = createAction("completedConfirmOrder");

export const pressedNewOrder = createAction("pressedNewOrder");

export const setPrice = createAction(
  "setPrice",
  (price: RemoteData<Error, { price: number; vat: number }>) => ({
    payload: price,
  })
);

export type State = {
  step: Step;
  businessInfo: BusinessInfo | undefined;
  orderType: OrderType;
  sender: Address | undefined;
  receiver: Address | undefined;
  parcel: Parcel | undefined;
  price: RemoteData<Error, { price: number; vat: number }>;
  order: Order | undefined;
};

export const initialState: State = {
  step: Step.EnterBusinessInfo,
  businessInfo: undefined,
  orderType: OrderType.SnapiNow,
  sender: undefined,
  receiver: undefined,
  parcel: undefined,
  price: initial,
  order: undefined,
};

export const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(completedEnterBusinessInfo, (state, action) => {
      const orderType = action.payload.orderType;
      state.businessInfo = action.payload;
      state.orderType = orderType;
      state.sender = action.payload.address;
      state.step = Step.EnterSender;
    })
    .addCase(completedEnterSender, (state, action) => {
      state.sender = action.payload.address;
      state.step = Step.EnterReceiver;
    })
    .addCase(completedEnterReceiver, (state, action) => {
      state.receiver = action.payload.address;
      state.step = Step.EnterParcelInfo;
    })
    .addCase(completedEnterParcelInfo, (state, action) => {
      state.parcel = action.payload.parcel;
      state.order = action.payload.order;
      state.step = Step.ConfirmOrder;
    })
    .addCase(completedConfirmOrder, (state, action) => {
      state.step = Step.OrderCompleted;
    })
    .addCase(setPrice, (state, action) => {
      state.price = action.payload;
    })
    .addCase(pressedBackInSender, (state, action) => {
      return initialState;
    })
    .addCase(pressedBackInReceiver, (state, action) => {
      state.receiver = action.payload;
      state.step = Step.EnterSender;
    })
    .addCase(pressedBackInParcelInfo, (state, action) => {
      state.parcel = action.payload;
      state.step = Step.EnterReceiver;
    })
    .addCase(pressedBackInConfirmOrder, (state, action) => {
      state.step = Step.EnterParcelInfo;
    })
    .addCase(pressedNewOrder, (state, action) => {
      state.sender = state.businessInfo?.address;
      state.receiver = undefined;
      state.parcel = undefined;
      state.price = initial;
      state.order = undefined;
      state.step = Step.EnterSender;
    })
);
