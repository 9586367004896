import { createMuiTheme } from "@material-ui/core";
import { Shadows } from "@material-ui/core/styles/shadows";

export const materialTheme = createMuiTheme({
  palette: {
    type: "light",
    primary: {
      main: "#000000",
    },
    error: {
      main: "#d0001c",
    },
  },
  typography: {
    fontFamily: "Epilogue",
    fontSize: 16,
  },
  // Disable button shadows
  shadows: Array(25).fill("none") as Shadows,
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#000000",
      },
    },
    MuiPickersToolbarButton: {
      toolbarBtn: {
        color: "#7c7208",
        "&:selected": { color: "#000000" },
      },
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        fontSize: "28px",
      },
    },
    MuiPickersDay: {
      daySelected: {
        color: "#ffffff",
        backgroundColor: "#000000",
        "&:hover": {
          backgroundColor: "#000000",
        },
      },
      current: {
        color: "#000000",
      },
    },
    MuiPickersModal: {
      withAdditionalAction: {
        color: "#000000",
      },
    },
    MuiIconButton: {
      root: {
        "&:hover": {
          backgroundColor: "inherit",
        },
      },
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } as any,
});
