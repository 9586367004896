import { Address } from "data/Address";
import { Parcel } from "data/Parcel";
import { createAction, createReducer } from "@reduxjs/toolkit";
import { RemoteData, initial } from "@devexperts/remote-data-ts";
import { Order } from "data/Order";

export enum Step {
  Sender = "Sender",
  Receiver = "Receiver",
  ParcelInfo = "ParcelInfo",
  Payment = "Payment",
  Completion = "Completion",
}

export const completedSender = createAction(
  "completedSender",
  (address: Address) => ({
    payload: { address },
  })
);

export const completedReceiver = createAction(
  "completedReceiver",
  (address: Address) => ({
    payload: { address },
  })
);

export const pressedBackInSender = createAction(
  "pressedBackInSender",
  (address: Address) => ({ payload: address })
);

export const pressedBackInReceiver = createAction(
  "pressedBackInReceiver",
  (address: Address) => ({ payload: address })
);

export const pressedBackInParcelInfo = createAction(
  "pressedBackInParcelInfo",
  (parcel: Parcel) => ({ payload: parcel })
);

export const pressedBackInPayment = createAction("pressedBackInPayment");

export const completedParcelInfo = createAction(
  "completedParcelInfo",
  (parcel: Parcel, order: Order) => ({ payload: { parcel, order } })
);

export const completedPayment = createAction(
  "completedPayment"
);

export const pressedNewOrder = createAction("pressedNewOrder");

export const setPrice = createAction(
  "setPrice",
  (price: RemoteData<Error, { price: number; vat: number }>) => ({
    payload: price,
  })
);

export type State = {
  step: Step;
  sender: Address | undefined;
  receiver: Address | undefined;
  parcel: Parcel | undefined;
  price: RemoteData<Error, { price: number; vat: number }>;
  order: Order | undefined;
};

export const initialState: State = {
  step: Step.Sender,
  sender: undefined,
  receiver: undefined,
  parcel: undefined,
  price: initial,
  order: undefined,
};

export const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(completedSender, (state, action) => {
      state.sender = action.payload.address;
      state.step = Step.Receiver;
    })
    .addCase(completedReceiver, (state, action) => {
      state.receiver = action.payload.address;
      state.step = Step.ParcelInfo;
    })
    .addCase(completedParcelInfo, (state, action) => {
      state.parcel = action.payload.parcel;
      state.order = action.payload.order;
      state.step = Step.Payment;
    })
    .addCase(setPrice, (state, action) => {
      state.price = action.payload;
    })
    .addCase(pressedBackInSender, (state, action) => {
      return initialState;
    })
    .addCase(pressedBackInReceiver, (state, action) => {
      state.receiver = action.payload;
      state.step = Step.Sender;
    })
    .addCase(pressedBackInParcelInfo, (state, action) => {
      state.parcel = action.payload;
      state.step = Step.Receiver;
    })
    .addCase(pressedBackInPayment, (state, action) => {
      state.step = Step.ParcelInfo;
    })
    .addCase(completedPayment, (state, action) => {
      state.step = Step.Completion;
    })
    .addCase(pressedNewOrder, (state, action) => {
      state.sender = undefined;
      state.receiver = undefined;
      state.parcel = undefined;
      state.price = initial;
      state.step = Step.Sender;
    })
);
