import "date-fns";
import React from "react";
import { useForm, Controller, RegisterOptions } from "react-hook-form";
import Button from "components/Button";
import FormItem from "components/FormItem";
import { Parcel } from "data/Parcel";
import { Address } from "data/Address";
import { BusinessInfo } from "data/BusinessInfo";
import {
  getCollectionTime,
  getCollectionTimeWithOffset,
} from "data/CollectionTimes";
import {
  RemoteData,
  pending,
  success,
  failure,
  initial,
  isSuccess,
  fold,
} from "@devexperts/remote-data-ts";
import Loader from "components/Loader";
import { useDebouncedCallback } from "use-debounce";
import { api } from "Api";
import { OrderType } from "data/OrderType";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import fiLocale from "date-fns/locale/fi";
import isAfter from "date-fns/isAfter";
import isSameDay from "date-fns/isSameDay";
import addMinutes from "date-fns/addMinutes";
import format from "date-fns/format";
import { ThemeProvider } from "@material-ui/core";
import { materialTheme } from "materialTheme";
import { assertNever } from "assertNever";
import CollectionTimePicker from "components/CollectionTimePicker";

type EnterParcelInfoProps = {
  businessInfo: BusinessInfo | undefined;
  orderType: OrderType;
  sender: Address;
  receiver: Address;
  price: RemoteData<Error, { price: number; vat: number }>;
  defaultValue: Parcel | undefined;
  setPrice: (price: RemoteData<Error, { price: number; vat: number }>) => void;
  pressedBack: (result: Parcel) => void;
  pressedContinue: (result: Parcel) => void;
};

type ParcelSizePreset = {
  size: "S" | "M" | "L";
  length: number;
  width: number;
  height: number;
};

const parcelSizePresets = [
  {
    size: "S",
    length: 80,
    width: 80,
    height: 40,
  },
  {
    size: "M",
    length: 100,
    width: 100,
    height: 60,
  },
  {
    size: "L",
    length: 200,
    width: 120,
    height: 150,
  },
] as const;

const toPreset = (size: ParcelSizePreset["size"]): ParcelSizePreset => {
  switch (size) {
    case "S":
      return parcelSizePresets[0];
    case "M":
      return parcelSizePresets[1];
    case "L":
      return parcelSizePresets[2];
  }
};

const numberPattern = /^[0-9]\d*[,\\.]?\d*$/;

const maxLength = 200;
const maxWidth = 120;
const maxHeight = 150;
const maxParcelWeight = 25;
const maxTotalWeight = 50;
const maxParcelCount = 5;

function parseDimensions(dimensions: {
  length: string;
  width: string;
  height: string;
}): { length: number; width: number; height: number } | undefined {
  const all = [
    dimensions.length,
    dimensions.width,
    dimensions.height,
  ].map((x) => parseFloat(x));
  const [length, width, height] = all;
  if (
    length > 0 &&
    length <= maxLength &&
    width > 0 &&
    width <= maxWidth &&
    height > 0 &&
    height <= maxHeight &&
    all.every((x) => !Number.isNaN(x))
  ) {
    return { length, width, height };
  } else {
    return undefined;
  }
}

function parseWeight(weight: string, maxWeight: number): number | undefined {
  const parsed = parseFloat(weight.replace(",", "."));
  if (parsed > 0 && parsed <= maxWeight && !Number.isNaN(weight)) {
    return parsed;
  } else {
    return undefined;
  }
}

const PriceDisplay = (
  props: Pick<EnterParcelInfoProps, "price"> & { className?: string }
) => (
  <div
    className={[
      "flex",
      "flex-col",
      "bg-gray-200",
      "p-5",
      "rounded-lg",
      props.className,
    ]
      .filter((x) => x !== undefined)
      .join(" ")}
  >
    <h2 className="snapi-title mb-4">Hinta</h2>
    {fold<Error, { price: number; vat: number }, any>(
      () => <p className="snapi-body">Täytä tiedot nähdäksesi hinnan.</p>,
      () => <Loader fill="#000000" className="mb-3" />,
      () => (
        <p className="snapi-body">
          Hinnan lataaminen epäonnistui. Tarkista osoitetiedot.
        </p>
      ),
      (p) => (
        <p className="snapi-body">
          Kuljetuksen hinta {p.price} € alv. {p.vat}%
        </p>
      )
    )(props.price)}
  </div>
);

const WeightFormItem = (props: {
  register: (rules?: RegisterOptions) => any;
  error: boolean;
  parcelCount: string;
  className?: string;
}) => {
  const parcelCountInt = parseInt(props.parcelCount, 10);
  const parcelCountOrOne: number | undefined = Number.isNaN(parcelCountInt)
    ? 1
    : parcelCountInt;
  const maxWeight = parcelCountOrOne > 1 ? maxTotalWeight : maxParcelWeight;
  return (
    <FormItem
      name="weight"
      label={`Toimituksen paino (pakettikohtainen max. ${maxParcelWeight} kg, toimituksen max. ${maxTotalWeight} kg)`}
      className={props.className}
      inputClassName="snapi-input-lg-33-minus-gap"
      error={
        props.error ? `Syötä kelvollinen paino (0 - ${maxWeight})` : undefined
      }
      required={true}
      pattern={numberPattern}
      register={props.register}
      validate={(value) => {
        const parsed = parseFloat(value.replace(",", "."));
        return parsed > 0 && parsed <= maxWeight;
      }}
    />
  );
};

const ParcelCountFormItem = (props: {
  register: (rules?: RegisterOptions) => any;
  error: boolean;
  className?: string;
}) => (
  <FormItem
    name="parcelCount"
    label={`Toimituksen pakettien määrä (max. ${maxParcelCount} kpl)`}
    className={props.className}
    inputClassName="snapi-input-lg-33-minus-gap"
    error={
      props.error
        ? `Syötä kelvollinen pakettien määrä (1 - ${maxParcelCount})`
        : undefined
    }
    required={true}
    pattern={/^\d+$/}
    register={props.register}
    validate={(value) => {
      const parsed = parseInt(value);
      return parsed > 0 && parsed <= maxParcelCount;
    }}
  />
);

const MessageToDriverFormItem = (props: {
  register: (rules?: RegisterOptions) => any;
  className?: string;
}) => (
  <div
    className={["flex", "flex-col", props.className]
      .filter((x) => x !== undefined)
      .join(" ")}
  >
    <label className="snapi-body" htmlFor="messageToDriver">
      Viesti kuljettajalle
    </label>

    <textarea
      className="snapi-input h-32 px-4 py-2"
      id="messageToDriver"
      name="messageToDriver"
      maxLength={400}
      ref={props.register({ required: false })}
    />
  </div>
);

const ButtonsFooter = (props: {
  pressedBack: () => void;
  continueDisabled: boolean;
}) => (
  <div className="flex flex-col-reverse sm:flex-row justify-between items-center mt-11 mb-8 space-y-4 space-y-reverse sm:space-y-0 space-x-0 sm:space-x-8">
    <button
      onClick={() => {
        props.pressedBack();
      }}
      type="button"
      className="snapi-button-normal w-full sm:w-auto"
    >
      Takaisin
    </button>
    <Button disabled={props.continueDisabled} className="w-full sm:w-auto">
      Jatka
    </Button>
  </div>
);

const SnapiTodayEnterParcelInfo = (props: EnterParcelInfoProps) => {
  const {
    register,
    handleSubmit,
    watch,
    errors,
    getValues,
    control,
    trigger,
  } = useForm<Parcel>({
    mode: "onBlur",
    defaultValues: {
      ...(props.defaultValue ? props.defaultValue : {}),
    },
  });

  const formData = watch([
    "length",
    "width",
    "height",
    "weight",
    "date",
    "parcelCount",
  ]);

  const priceUpdate = useDebouncedCallback(
    (priceProps: {
      sender: Address;
      receiver: Address;
      businessInfo: BusinessInfo | undefined;
      dimensions: { length: number; width: number; height: number } | undefined;
      date: Date | undefined;
      orderType: OrderType;
    }) => {
      api
        .fetchPrice(priceProps)
        .then((x) => setPrice(success(x)))
        .catch((e) => setPrice(failure(e)));
    },
    1000
  );

  React.useEffect(() => {
    if (formData.weight.length > 0) {
      trigger("weight");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.parcelCount]);

  const { sender, receiver, price, setPrice, businessInfo, orderType } = props;

  React.useEffect(() => {
    if (!sender || !receiver) {
      return;
    }

    const parsedWeight = parseWeight(formData.weight, maxTotalWeight);
    const parsedDimensions = parseDimensions(formData);

    if (parsedDimensions === undefined || parsedWeight === undefined) {
      priceUpdate.cancel();
      setPrice(initial);
      return;
    }

    const date =
      formData.date ??
      getCollectionTimeWithOffset(businessInfo!.collectionTimes, new Date());

    setPrice(pending);

    priceUpdate.callback({
      sender,
      receiver,
      dimensions: parsedDimensions,
      date: date,
      businessInfo: businessInfo,
      orderType: orderType,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formData.date,
    formData.length,
    formData.height,
    formData.width,
    formData.weight,
    priceUpdate,
  ]);

  const onSubmit = (formData: Parcel) => {
    if (!sender || !receiver) {
      return;
    }

    if (!isSuccess(price)) {
      return;
    }

    const parcel: Parcel = {
      ...formData,
      date:
        formData.date ??
        getCollectionTimeWithOffset(businessInfo!.collectionTimes, new Date()),
    };

    props.pressedContinue(parcel);
  };

  return (
    <ThemeProvider theme={materialTheme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fiLocale}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <h1 className="snapi-title-lg mb-11">Toimituksen tiedot</h1>

          <div className="flex flex-col space-y-6 md:grid md:grid-cols-3 md:gap-x-3 md:gap-y-6 md:space-y-0">
            <FormItem
              name="length"
              label={<>Toimituksen pituus <span className="lg:block">(max {maxLength} cm)</span></>}
              className="col-span-3 lg:col-span-1"
              error={
                errors.length
                  ? `Syötä kelvollinen pituus (0 - ${maxLength})`
                  : undefined
              }
              required={true}
              pattern={numberPattern}
              register={register}
              validate={(value) => {
                const parsed = parseFloat(value.replace(",", "."));
                return parsed > 0 && parsed <= maxLength;
              }}
            />

            <FormItem
              name="width"
              label={<>Toimituksen leveys <span className="lg:block">(max {maxWidth} cm)</span></>}
              className="col-span-3 lg:col-span-1"
              error={
                errors.width
                  ? `Syötä kelvollinen leveys (0 - ${maxWidth})`
                  : undefined
              }
              required={true}
              pattern={numberPattern}
              register={register}
              validate={(value) => {
                const parsed = parseFloat(value.replace(",", "."));
                return parsed > 0 && parsed <= maxWidth;
              }}
            />

            <FormItem
              name="height"
              label={<>Toimituksen korkeus <span className="lg:block">(max {maxHeight} cm)</span></>}
              className="col-span-3 lg:col-span-1"
              error={
                errors.height
                  ? `Syötä kelvollinen korkeus (0 - ${maxHeight})`
                  : undefined
              }
              required={true}
              pattern={numberPattern}
              register={register}
              validate={(value) => {
                const parsed = parseFloat(value.replace(",", "."));
                return parsed > 0 && parsed <= maxHeight;
              }}
            />

            <ParcelCountFormItem
              register={register}
              error={errors.parcelCount !== undefined}
              className="col-span-3"
            />

            <WeightFormItem
              register={register}
              error={errors.weight !== undefined}
              parcelCount={formData.parcelCount}
              className="col-span-3"
            />

            <div className="flex flex-col col-span-3">
              <label className="snapi-body">Noutoaika</label>

              <Controller
                render={(p) => (
                  <CollectionTimePicker
                    value={p.value}
                    onChange={(d) => p.onChange(d)}
                    collectionTimes={businessInfo!.collectionTimes}
                  />
                )}
                rules={{
                  validate: (d) =>
                    isAfter(
                      getCollectionTime(
                        businessInfo!.collectionTimes,
                        d ?? new Date()
                      ),
                      new Date()
                    ),
                }}
                control={control}
                name="date"
              />

              {errors.date && (
                <span className="col-span-2 lg:col-span-3 snapi-input-error mt-1">
                  Valitse toinen päivä. Tilaus ei ehdi enää tämän päivän
                  keräilyyn.
                </span>
              )}
              {(formData.date === undefined ||
                isSameDay(formData.date, new Date())) && (
                <div className="col-span-2 lg:col-span-3 mt-2 snapi-body">
                  Kello{" "}
                  {format(
                    getCollectionTime(
                      businessInfo!.collectionTimes,
                      formData.date ?? new Date()
                    ),
                    "HH:mm",
                    { locale: fiLocale }
                  )}{" "}
                  mennessä tehdyt tilaukset ehtivät tämän päivän keräilyyn.
                </div>
              )}
            </div>

            <MessageToDriverFormItem
              className="col-span-3"
              register={register}
            />

            <PriceDisplay price={price} className="col-span-3" />
          </div>

          <ButtonsFooter
            pressedBack={() => props.pressedBack(getValues())}
            continueDisabled={!isSuccess(props.price)}
          />
        </form>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

const SnapiNowEnterParcelInfo = (props: EnterParcelInfoProps) => {
  const {
    register,
    handleSubmit,
    watch,
    errors,
    getValues,
    control,
    setValue,
    trigger,
  } = useForm<Parcel & { when: "now" | "later" }>({
    mode: "onBlur",
    defaultValues: {
      ...(props.defaultValue ? props.defaultValue : {}),
      ...{ when: props.defaultValue?.date ? "later" : "now" },
    },
  });

  const formData = watch(["weight", "size", "date", "when", "parcelCount"]);

  const priceUpdate = useDebouncedCallback(
    (priceProps: {
      sender: Address;
      receiver: Address;
      businessInfo: BusinessInfo | undefined;
      dimensions: { length: number; width: number; height: number } | undefined;
      date: Date | undefined;
      orderType: OrderType;
    }) => {
      api
        .fetchPrice(priceProps)
        .then((x) => setPrice(success(x)))
        .catch((e) => setPrice(failure(e)));
    },
    1000
  );

  React.useEffect(() => {
    if (formData.weight.length > 0) {
      trigger("weight");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.parcelCount]);

  const { sender, receiver, price, setPrice, businessInfo, orderType } = props;

  React.useEffect(() => {
    if (!sender || !receiver) {
      return;
    }

    const date = formData.when === "now" ? undefined : formData.date;

    const parsedWeight = parseWeight(formData.weight, maxTotalWeight);

    if (parsedWeight === undefined || formData.size === undefined) {
      priceUpdate.cancel();
      setPrice(initial);
      return;
    }

    const dimensions = toPreset(formData.size);

    setPrice(pending);

    priceUpdate.callback({
      sender,
      receiver,
      dimensions: dimensions,
      date: date,
      businessInfo: businessInfo,
      orderType: orderType,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formData.date,
    formData.weight,
    formData.size,
    formData.when,
    priceUpdate,
  ]);

  const onSubmit = (formData: Parcel) => {
    if (!sender || !receiver) {
      return;
    }

    if (!isSuccess(price)) {
      return;
    }

    if (!formData.size || formData.parcelCount === undefined) {
      return;
    }

    const preset = toPreset(formData.size);

    const parcel: Parcel = {
      length: `${preset.length}`,
      width: `${preset.width}`,
      height: `${preset.height}`,
      weight: formData.weight,
      size: formData.size,
      date: formData.date,
      messageToDriver: formData.messageToDriver,
      parcelCount: formData.parcelCount,
    };

    props.pressedContinue(parcel);
  };

  return (
    <ThemeProvider theme={materialTheme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fiLocale}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <h1 className="snapi-title-lg mb-11">Toimituksen tiedot</h1>

          <div className="flex flex-col space-y-6 md:grid md:grid-cols-2 md:gap-x-3 md:gap-y-6 md:space-y-0">
            <div className="flex flex-col col-span-2">
              <div className="snapi-body">Toimituksen koko</div>

              <div className="flex flex-col items-stretch md:items-start space-y-2">
                {parcelSizePresets.map((preset) => (
                  <div key={`package_size_${preset.size}`}>
                    <input
                      id={`package_size_${preset.size}`}
                      name="size"
                      type="radio"
                      value={preset.size}
                      className="hidden"
                      ref={register({ required: true })}
                    />
                    <label
                      className="snapi-checkbox w-auto md:w-64"
                      htmlFor={`package_size_${preset.size}`}
                    >
                      {preset.size} (max. {preset.length}x{preset.width}x
                      {preset.height} cm)
                    </label>
                  </div>
                ))}
              </div>

              {errors.size && (
                <span className="snapi-input-error mt-1">
                  Valitse toimituksen koko
                </span>
              )}
            </div>

            <ParcelCountFormItem
              register={register}
              error={errors.parcelCount !== undefined}
              className="col-span-2"
            />

            <WeightFormItem
              register={register}
              error={errors.weight !== undefined}
              parcelCount={formData.parcelCount}
              className="col-span-2"
            />

            <div className="flex flex-col col-span-2">
              <label className="snapi-body">Noutoaika</label>

              <div className="grid grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-2">
                <div className="row-start-1 col-span-1">
                  <input
                    id="when_now"
                    name="when"
                    type="radio"
                    value="now"
                    className="hidden"
                    ref={register()}
                  />
                  <label htmlFor="when_now" className="snapi-checkbox">
                    Heti
                  </label>
                </div>
                <div className="row-start-2 lg:row-start-1 col-span-1">
                  <input
                    id="when_later"
                    name="when"
                    type="radio"
                    value="later"
                    className="hidden"
                    onChange={(e) => {
                      if (e.target.checked) {
                        setTimeout(() => {
                          setValue("date", addMinutes(new Date(), 30));
                        }, 0);
                      }
                    }}
                    ref={register()}
                  />
                  <label htmlFor="when_later" className="snapi-checkbox">
                    Anna haluamasi aika
                  </label>
                </div>

                {formData.when === "now" && (
                  <div className="row-start-2 lg:row-start-1 col-span-1" />
                )}
                {formData.when === "later" && (
                  <div className="row-start-2 lg:row-start-1 col-span-1">
                    <Controller
                      render={(p) => (
                        <DateTimePicker
                          id="date"
                          format="dd.MM.yyyy HH:mm"
                          ampm={false}
                          value={p.value}
                          disablePast={true}
                          cancelLabel="Peruuta"
                          okLabel="Ok"
                          onChange={p.onChange}
                        />
                      )}
                      rules={{ validate: (d) => isAfter(d, new Date()) }}
                      control={control}
                      name="date"
                    />

                    {errors.date && (
                      <span className="snapi-input-error block mt-1">
                        Syötä kelvollinen päivämäärä ja kellonaika.
                      </span>
                    )}
                  </div>
                )}
              </div>
            </div>

            <MessageToDriverFormItem
              register={register}
              className="col-span-2"
            />

            <PriceDisplay price={price} className="col-span-2" />
          </div>

          <ButtonsFooter
            pressedBack={() => props.pressedBack(getValues())}
            continueDisabled={!isSuccess(props.price)}
          />
        </form>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

const EnterParcelInfo = (props: EnterParcelInfoProps) => {
  if (props.orderType === OrderType.SnapiNow) {
    return <SnapiNowEnterParcelInfo {...props} />;
  } else if (props.orderType === OrderType.SnapiToday) {
    return <SnapiTodayEnterParcelInfo {...props} />;
  } else {
    return assertNever(props.orderType);
  }
};

export default EnterParcelInfo;
