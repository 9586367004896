import addMinutes from "date-fns/addMinutes";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import setSeconds from "date-fns/setSeconds";
import setMilliseconds from "date-fns/setMilliseconds";
import getDay from "date-fns/getDay";

export type CollectionTimes = {
  mon: string;
  tue: string;
  wed: string;
  thu: string;
  fri: string;
  sat: string;
  sun: string;
};

export function setHoursAndMinutes(
  date: Date,
  hours: number,
  minutes: number
): Date {
  return setMilliseconds(
    setSeconds(setMinutes(setHours(date, hours), minutes), 0),
    0
  );
}

export const collectionOffsetMinutes = 30;

export function dayNumberToWeekdayString(
  dayNumber: 0 | 1 | 2 | 3 | 4 | 5 | 6
): keyof CollectionTimes {
  switch (dayNumber) {
    case 0:
      return "sun";
    case 1:
      return "mon";
    case 2:
      return "tue";
    case 3:
      return "wed";
    case 4:
      return "thu";
    case 5:
      return "fri";
    case 6:
      return "sat";
  }
}

export function getCollectionTimeWithOffset(
  collectionTimes: CollectionTimes,
  date: Date,
  now: Date = new Date()
): Date {
  const collectionTime = getCollectionTime(collectionTimes, date);

  return addMinutes(collectionTime, collectionOffsetMinutes);
}

export function getCollectionTime(
  collectionTimes: CollectionTimes,
  date: Date
): Date {
  const weekday = dayNumberToWeekdayString(getDay(date));

  const collectionTimeString = collectionTimes[weekday];
  const [hours, minutes] = collectionTimeString
    .split(":")
    .map((x) => parseInt(x, 10));
  return setHoursAndMinutes(date, hours, minutes);
}
