import React from "react";
import addDays from "date-fns/addDays";
import isBefore from "date-fns/isBefore";
import startOfDay from "date-fns/startOfDay";
import isSameDay from "date-fns/isSameDay";
import { DatePicker } from "@material-ui/pickers";

import {
  CollectionTimes,
  getCollectionTimeWithOffset,
} from "data/CollectionTimes";

type CollectionTimePickerProps = {
  collectionTimes: CollectionTimes;
  value: Date | undefined;
  onChange: (date: Date) => void;
};

function isTodayOrBefore(date: Date, now: Date = new Date()): boolean {
  const startOfTomorrow = startOfDay(addDays(now, 1));
  return isBefore(date, startOfTomorrow);
}

const CollectionTimePicker = ({
  collectionTimes,
  value,
  onChange,
}: CollectionTimePickerProps) => {
  const today: boolean = value === undefined || isSameDay(value, new Date());

  return (
    <div className="grid grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-2">
      <div className="row-start-1 col-span-1">
        <input
          id="when_today"
          name="when"
          type="radio"
          value="today"
          onChange={(e) =>
            !today &&
            e.target.checked &&
            onChange(getCollectionTimeWithOffset(collectionTimes, new Date()))
          }
          checked={today}
          className="hidden"
        />
        <label htmlFor="when_today" className="snapi-checkbox">
          Tänään
        </label>
      </div>
      <div className="row-start-2 lg:row-start-1 col-span-1">
        <input
          id="when_chooseDate"
          name="when"
          type="radio"
          value="chooseDate"
          className="hidden"
          checked={!today}
          onChange={(e) =>
            today &&
            e.target.checked &&
            onChange(
              getCollectionTimeWithOffset(
                collectionTimes,
                addDays(new Date(), 1)
              )
            )
          }
        />
        <label htmlFor="when_chooseDate" className="snapi-checkbox">
          Valitse päivä
        </label>
      </div>

      {today && <div className="row-start-2 lg:row-start-1 col-span-1" />}
      {!today && (
        <div className="row-start-2 lg:row-start-1 col-span-1">
          <DatePicker
            id="date"
            format="dd.MM.yyyy"
            value={value}
            shouldDisableDate={(date) => !date || isTodayOrBefore(date)}
            cancelLabel="Peruuta"
            okLabel="Ok"
            onChange={(date) => {
              if (date) {
                const collectionTime = getCollectionTimeWithOffset(
                  collectionTimes,
                  date
                );
                onChange(collectionTime);
              }
            }}
          />
        </div>
      )}
    </div>
  );
};

export default CollectionTimePicker;
