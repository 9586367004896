import { Address } from "data/Address";
import { BusinessInfo } from "data/BusinessInfo";
import { OrderType } from "data/OrderType";
import { config } from "config";

export type Api = {
  fetchPrice(params: {
    sender: Pick<Address, "postCode" | "streetAddress">;
    receiver: Pick<Address, "postCode" | "streetAddress">;
    businessInfo: Pick<BusinessInfo, "accountIdentifier" | "pin"> | undefined;
    dimensions: { length: number; width: number; height: number } | undefined;
    date: Date | undefined;
    orderType: OrderType;
  }): Promise<{ price: number; vat: number }>;
};

export const api: Api = {
  fetchPrice({
    sender,
    receiver,
    businessInfo,
    dimensions,
    date,
    orderType,
  }): Promise<{ price: number; vat: number }> {
    const quoteRequest = {
      date: date?.toISOString(),
      dimensions: dimensions,
      from: {
        streetAddress: sender.streetAddress,
        postCode: sender.postCode,
      },
      to: {
        streetAddress: receiver.streetAddress,
        postCode: receiver.postCode,
      },
      type: orderType,
    };

    return fetch("/api/quote", {
      method: "POST",
      body: JSON.stringify(quoteRequest),
      headers: {
        "X-API-Key": config.apiKey,
        ...(businessInfo
          ? {
              Authorization: `Basic ${btoa(
                businessInfo.accountIdentifier + ":" + businessInfo.pin
              )}`,
            }
          : {}),
      },
    })
      .then((res) => res.json())
      .then(({ price }: { price: number }) => ({ price, vat: 24 }));
  },
};
