const OrderLayout = (props: {
  stepper: JSX.Element;
  content: JSX.Element;
  sidebar: JSX.Element | null;
}) => (
  <div className="md:grid md:grid-cols-10 gap-x-20">
    <div className="md:col-span-10">{props.stepper}</div>

    <div className="md:col-span-6 lg:col-span-7">{props.content}</div>

    <div className="md:col-span-4 lg:col-span-3">{props.sidebar}</div>
  </div>
);

export default OrderLayout;
