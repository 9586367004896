import { Address } from "data/Address";
import ArrowDown from "components/ArrowDown";
import SenderReceiverInfo from "components/SenderReceiverInfo";

type DetailsSidebarProps = {
  sender: Address | undefined;
  receiver: Address | undefined;
  senderTitle: string;
  receiverTitle: string;
  showSender: boolean;
  showReceiver: boolean;
};

const DetailsSidebar = ({
  sender,
  receiver,
  receiverTitle,
  senderTitle,
  showSender,
  showReceiver,
}: DetailsSidebarProps) => (
  <div className="flex flex-col space-y-4">
    {showSender && sender && (
      <div className="p-5">
        <h2 className="snapi-title mb-4">{senderTitle}</h2>
        <SenderReceiverInfo {...sender} />
      </div>
    )}
    {showReceiver && receiver && <ArrowDown className="ml-5" />}
    {showReceiver && receiver && (
      <div className="p-5">
        <h2 className="snapi-title mb-4">{receiverTitle}</h2>
        <SenderReceiverInfo {...receiver} />
      </div>
    )}
  </div>
);

export default DetailsSidebar;
