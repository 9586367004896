import React from "react";
import { useForm } from "react-hook-form";
import Button from "components/Button";
import FormItem from "components/FormItem";
import { Link } from "react-router-dom";
import { OrderRoute } from "order/OrderRoute";
import { delayed } from "delayed";
import { Address } from "data/Address";
import { BusinessInfo } from "data/BusinessInfo";
import { CollectionTimes } from "data/CollectionTimes";
import { OrderType } from "data/OrderType";

type EnterBusinessInfoResult = {
  accountIdentifier: string;
  pin: string;
};

type EnterBusinessInfoProps = {
  pressedContinue: (result: BusinessInfo & { orderType: OrderType }) => void;
};

type BusinessInfoResponse = {
  email: string | undefined;
  address: Address;
  collectionTimes: CollectionTimes;
};

function fetchBusinessInfo({
  accountIdentifier,
  pin,
}: EnterBusinessInfoResult): Promise<BusinessInfoResponse> {
  return delayed(
    fetch("api/business_info", {
      headers: {
        Authorization: `Basic ${btoa(accountIdentifier + ":" + pin)}`,
      },
    }).then((response) => response.json())
  );
}

const EnterBusinessInfo = (props: EnterBusinessInfoProps) => {
  const { register, handleSubmit, errors } = useForm<EnterBusinessInfoResult>();
  const [loading, setLoading] = React.useState(false);
  const [orderType, setOrderType] = React.useState<OrderType>(
    OrderType.SnapiNow
  );

  const onSubmit = (data: EnterBusinessInfoResult) => {
    setLoading(true);
    fetchBusinessInfo(data)
      .then((response) => {
        setLoading(false);
        props.pressedContinue({
          ...data,
          email: response.email,
          address: response.address,
          collectionTimes: response.collectionTimes,
          orderType: orderType,
        });
      })
      .catch(() => {
        setLoading(false);
        window.alert(
          "Väärä käyttäjätilin viite tai PIN. Ole hyvä ja yritä uudelleen."
        );
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h1 className="snapi-title-lg mb-11">Tilaa uusi kuljetus</h1>

      <div className="flex flex-col space-y-6">
        <FormItem
          name="accountIdentifier"
          label="Käyttäjätilin tunniste"
          className="sm:w-64"
          error={
            errors.accountIdentifier
              ? "Syötä kelvollinen käyttäjätilin tunniste"
              : undefined
          }
          required={true}
          register={register}
          pattern={/^[0-9a-zA-z-]+$/}
        />

        <FormItem
          name="pin"
          label="PIN"
          className="sm:w-64"
          error={errors.pin ? "Syötä kelvollinen PIN" : undefined}
          required={true}
          register={register}
          type="password"
        />
      </div>

      <div className="flex flex-col-reverse sm:flex-row justify-start items-center mt-11 mb-8 space-y-4 space-y-reverse sm:space-y-0 space-x-0 sm:space-x-8">
        <Button
          className="w-full sm:w-auto"
          disabled={loading}
          onClick={() => {
            setOrderType(OrderType.SnapiNow);
          }}
          loading={loading && orderType === OrderType.SnapiNow}
        >
          Snäpi
        </Button>
      </div>

      <span className="snapi-body block mt-4">
        Henkilöasiakas?{" "}
        <Link className="snapi-link" to={OrderRoute.Consumer}>
          Siirry tänne
        </Link>
      </span>

      <div className="mt-11 space-y-8 md:grid md:grid-cols-1 md:gap-x-8 md:space-y-0">
        <div className="space-y-4">
          <h3 className="snapi-title">SNÄPI</h3>
          <p className="snapi-body">
            Kun tilaat Snäpin, olemme noutamassa toimitusta saman tien.
            Viimeistään kahdessa kymmenessä minuutissa. Ja kun vientipaikka on
            20 km säteellä, ovat tavarat perillä jopa tunnissa tilauksestasi!
          </p>
          <h4 className="snapi-body font-bold">
            Toimitus vielä tänään illalla, tai heti aamulla aikaisin?
          </h4>
          <p className="snapi-body">
            Ei hätää. Toimimme 24/7! Toimitukset lähtevät juuri silloin kun
            tarvitset. Voit myös ajastaa kuljetuksen lähtemään parin tunnin
            päästä tai vaikka ensi viikolla.
          </p>
        </div>
      </div>

      <a
        className="snapi-link block mt-4"
        href="https://snapi.fi/lisatiedot/kayttoehdot/"
      >
        Käyttöehdot
      </a>
    </form>
  );
};
export default EnterBusinessInfo;
