import { Address } from "data/Address";

const SenderReceiverInfo = ({
  name,
  streetAddress,
  postCode,
  city,
  phone,
}: Address) => (
  <p className="snapi-body">
    {name}
    <br />
    {streetAddress}
    <br />
    {postCode} {city} <br />
    {phone}
  </p>
);

export default SenderReceiverInfo;
