import React from "react";
import Button from "components/Button";
import SenderReceiverInfo from "components/SenderReceiverInfo";
import fiLocale from "date-fns/locale/fi";
import { Order, formatDate } from "data/Order";

type OrderCompletedProps = {
  senderTitle: string;
  receiverTitle: string;
  order: Order;
  className?: string;
  pressedNewOrder: () => void;
};

const OrderCompleted = ({
  senderTitle,
  receiverTitle,
  order,
  className,
  pressedNewOrder,
}: OrderCompletedProps) => {
  const now = React.useMemo(() => new Date(), []);

  return (
    <div className={className}>
      <h1 className="snapi-title-lg mb-11">Tilaus tehty</h1>
      <div className="flex flex-col space-y-6 md:grid md:grid-cols-2 md:gap-x-3 md:gap-y-9 md:space-y-0">
        <div>
          <h2 className="snapi-title mb-4">{senderTitle}</h2>
          <SenderReceiverInfo {...order.sender} {...order.sender.address} />
        </div>
        <div>
          <h2 className="snapi-title mb-4">{receiverTitle}</h2>
          <SenderReceiverInfo {...order.receiver} {...order.receiver.address} />
        </div>

        <div>
          <h2 className="snapi-title mb-4">Toimituksen koko</h2>
          <p className="snapi-body">
            Pituus: {order.parcels[0].dimensions.length}cm
            <br />
            Leveys: {order.parcels[0].dimensions.width}cm <br />
            Korkeus: {order.parcels[0].dimensions.height}cm <br />
            Pakettien määrä: {order.parcelCount}<br />
            Paino: {order.parcels[0].weight}kg
          </p>
        </div>

        <div>
          <h2 className="snapi-title mb-4">Toimitus</h2>
          <p className="snapi-body">
            Noutoaika: {formatDate(now, order, fiLocale)}
            <br />
            <span>{order.price} € alv. 24%</span>
          </p>
        </div>

        <div>
          <h2 className="snapi-title mb-4">Viesti</h2>
          <p className="snapi-body">{order.receiver.instructions}</p>
        </div>
      </div>
      <Button className="mt-11" onClick={pressedNewOrder}>
        Tee uusi tilaus
      </Button>
    </div>
  );
};

export default OrderCompleted;
