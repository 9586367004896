import React from "react";
import { RegisterOptions } from "react-hook-form";

type FormItemProps = {
  register: (rules?: RegisterOptions) => any;
  name: string;
  label: string | JSX.Element;
  error: string | undefined;
  required?: boolean;
  pattern?: RegExp;
  type?: "text" | "password" | "datetime-local";
  className?: string;
  inputClassName?: string;
  validate?: (data: string) => boolean;
};

const FormItem = (props: FormItemProps) => (
  <div
    className={["flex flex-col", props.className]
      .filter((x) => x !== undefined)
      .join(" ")}
  >
    <label className="snapi-body" htmlFor={props.name}>
      {props.label}
    </label>

    <input
      className={["snapi-input", props.inputClassName]
        .filter((x) => x !== undefined)
        .join(" ")}
      id={props.name}
      name={props.name}
      type={props.type ? props.type : "text"}
      ref={props.register({
        required: props.required,
        pattern: props.pattern,
        validate: props.validate,
      })}
    />

    {props.error && (
      <span className="snapi-input-error mt-1">{props.error}</span>
    )}
  </div>
);

export default FormItem;
