import { Order } from "data/Order";
import Button from "components/Button";
import OrderDetails from "components/OrderDetails";

type ConfirmOrderProps = {
  order: Order;
  processing: boolean;
  pressedOrder: () => void;
  pressedBack: () => void;
};

const ConfirmOrder = ({
  order,
  processing,
  pressedBack,
  pressedOrder,
}: ConfirmOrderProps) => {
  return (
    <div>
      <h1 className="snapi-title-lg mb-11">Tarkista ja tilaa</h1>

      <OrderDetails
        order={order}
        senderTitle="Noutopaikan tiedot"
        receiverTitle="Vastaanottaja"
      />

      <div className="flex flex-col col-span-2 bg-gray-200 p-5 rounded-lg">
        <h2 className="snapi-title mb-4">Hinta</h2>
        <p className="snapi-body">Kuljetuksen hinta {order.price} € alv. 24%</p>
      </div>

      <div className="flex flex-col-reverse sm:flex-row justify-between items-center mt-11 mb-8 space-y-4 space-y-reverse sm:space-y-0 space-x-0 sm:space-x-8">
        <button
          onClick={pressedBack}
          type="button"
          className="snapi-button-normal w-full sm:w-auto"
        >
          Takaisin
        </button>
        <Button
          className="snapi-button w-full sm:w-auto"
          disabled={processing}
          loading={processing}
          onClick={pressedOrder}
          id="submit"
        >
          Tilaa
        </Button>
      </div>
    </div>
  );
};

export default ConfirmOrder;
