import { OrderType } from "data/OrderType";
import addMinutes from "date-fns/addMinutes";
import isAfter from "date-fns/isAfter";
import format from "date-fns/format";
import { Locale } from "date-fns";

export type Order = {
  date: string | undefined; // undefined = now order
  type: OrderType;
  receiptEmail: string | undefined;
  sender: {
    name: string;
    phone: string;
    address: {
      streetAddress: string;
      postCode: string;
      city: string;
      country: string;
    };
    instructions: string;
  };
  receiver: {
    name: string;
    phone: string;
    address: {
      streetAddress: string;
      postCode: string;
      city: string;
      country: string;
    };
    instructions: string;
  };
  price: number;
  parcels: [
    {
      dimensions: { length: number; width: number; height: number };
      weight: number;
    }
  ];
  parcelCount: number | undefined;
};

export function formatDate(
  now: Date,
  order: Pick<Order, "date" | "type">,
  locale: Locale
): string {
  if (order.type === OrderType.SnapiNow) {
    if (order.date === undefined) {
      return "Noin 20 minuutin sisällä";
    } else {
      const nowPlus20 = addMinutes(now, 20);
      const date = new Date(order.date);
      if (isAfter(nowPlus20, date)) {
        return "Noin 20 minuutin sisällä";
      } else {
        return format(date, "dd.MM.yyyy HH:mm", {
          locale: locale,
        });
      }
    }
  } else {
    return format(new Date(order.date!), "dd.MM.yyyy HH:mm", {
      locale: locale,
    });
  }
}
