type StepperProps = {
  step: number;
  size: number;
  className?: string;
};

const Stepper = ({ step, size, className }: StepperProps) => (
  <div
    className={["h-6 inline-flex border-gray-200 relative", className]
      .filter((x) => x !== undefined)
      .join(" ")}
  >
    <div className="absolute w-full h-1 top-1.5 bg-black"></div>
    {Array(size)
      .fill(0)
      .map((_, index) => (
        <div
          key={`step-${index}`}
          className={[
            "w-4 h-4 rounded-full bg-black relative",
            index !== size - 1 ? "mr-10" : undefined,
          ]
            .filter((x) => x !== undefined)
            .join(" ")}
        >
          {step < index && (
            <div className="bg-white w-2 h-2 rounded-full left-1 top-1 absolute"></div>
          )}
        </div>
      ))}
  </div>
);

export default Stepper;
