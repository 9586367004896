import React from "react";

import { assertNever } from "assertNever";
import EnterAddress from "./EnterAddress";
import EnterParcelInfo from "./EnterParcelInfo";
import OrderCompleted from "./OrderCompleted";
import {
  reducer,
  initialState,
  Step,
  completedSender,
  completedReceiver,
  completedPayment,
  pressedBackInReceiver,
  pressedBackInParcelInfo,
  completedParcelInfo,
  pressedBackInSender,
  pressedNewOrder,
  pressedBackInPayment,
  setPrice,
} from "order/consumerState";
import DetailsSidebar from "./DetailsSidebar";
import Payment from "./Payment";
import Stepper from "components/Stepper";
import OrderLayout from "./OrderLayout";
import { Order } from "data/Order";
import { toUndefined } from "@devexperts/remote-data-ts";
import { OrderType } from "data/OrderType";
import { defaultParcel } from "data/Parcel";

function stepNumber(step: Step): number {
  switch (step) {
    case Step.Sender:
      return 0;
    case Step.Receiver:
      return 1;
    case Step.ParcelInfo:
      return 2;
    case Step.Payment:
      return 3;
    case Step.Completion:
      return 4;
    default:
      assertNever(step);
  }
}

const ConsumerOrderFlow = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { step, sender, receiver, price } = state;

  let content: JSX.Element;

  switch (step) {
    case Step.Sender:
      content = (
        <EnterAddress
          key="sender"
          title="Mistä haetaan?"
          defaultValue={sender}
          pressedBack={(address) => {
            dispatch(pressedBackInSender(address));
          }}
          backText="Palaa alkuun"
          backVisible={false}
          pressedContinue={(address) => {
            dispatch(completedSender(address));
          }}
          showBusinessLink={true}
        />
      );
      break;
    case Step.Receiver:
      content = (
        <EnterAddress
          key="receiver"
          title="Minne kuljetetaan?"
          defaultValue={receiver}
          pressedBack={(address) => {
            dispatch(pressedBackInReceiver(address));
          }}
          backText="Takaisin"
          backVisible={true}
          pressedContinue={(address) => {
            dispatch(completedReceiver(address));
          }}
          showBusinessLink={false}
        />
      );
      break;
    case Step.ParcelInfo:
      content = (
        <EnterParcelInfo
          sender={sender!}
          receiver={receiver!}
          businessInfo={undefined}
          orderType={OrderType.SnapiNow}
          defaultValue={state.parcel ?? defaultParcel}
          price={state.price}
          setPrice={(p) => dispatch(setPrice(p))}
          pressedBack={(parcel) => {
            dispatch(pressedBackInParcelInfo(parcel));
          }}
          pressedContinue={(p) => {
            if (!sender || !receiver) {
              return;
            }
            const order: Order = {
              date: p.date?.toISOString(),
              type: OrderType.SnapiNow,
              receiptEmail: undefined,
              sender: {
                name: sender.name,
                phone: sender.phone,
                address: {
                  streetAddress: sender.streetAddress,
                  postCode: sender.postCode,
                  city: sender.city,
                  country: sender.country,
                },
                instructions: p.messageToDriver,
              },
              receiver: {
                name: receiver.name,
                phone: receiver.phone,
                address: {
                  streetAddress: receiver.streetAddress,
                  postCode: receiver.postCode,
                  city: receiver.city,
                  country: receiver.country,
                },
                instructions: p.messageToDriver,
              },
              price: toUndefined(price)?.price!,
              parcels: [
                {
                  dimensions: {
                    length: parseFloat(p.length.replace(",", ".")),
                    width: parseFloat(p.width.replace(",", ".")),
                    height: parseFloat(p.height.replace(",", ".")),
                  },
                  weight: parseFloat(p.weight.replace(",", ".")),
                },
              ],
              parcelCount: parseInt(p.parcelCount, 10),
            };

            dispatch(completedParcelInfo(p, order));
          }}
        />
      );
      break;
    case Step.Payment:
      content = (
        <Payment
          order={state.order!}
          pressedBack={() => {
            dispatch(pressedBackInPayment());
          }}
          completedPayment={() => {
            dispatch(completedPayment());
          }}
        />
      );
      break;
    case Step.Completion:
      content = (
        <OrderCompleted
          order={state.order!}
          senderTitle="Täältä"
          receiverTitle="Tänne"
          pressedNewOrder={() => {
            dispatch(pressedNewOrder());
          }}
        />
      );
      break;

    default:
      assertNever(step);
  }

  return (
    <OrderLayout
      stepper={<Stepper className="mb-4" step={stepNumber(step)} size={5} />}
      content={content}
      sidebar={
        step !== Step.Payment && step !== Step.Completion ? (
          <DetailsSidebar
            senderTitle="Mistä"
            receiverTitle="Minne"
            sender={sender}
            receiver={receiver}
            showSender={stepNumber(step) > stepNumber(Step.Sender)}
            showReceiver={stepNumber(step) > stepNumber(Step.Receiver)}
          />
        ) : null
      }
    />
  );
};

export default ConsumerOrderFlow;
