import React from "react";
import Button from "components/Button";

type StartProps = {
  pressedBusiness: () => void;
  pressedConsumer: () => void;
};

const Start = (props: StartProps) => {
  return (
    <div>
      <h1 className="snapi-title-lg mb-11">Tilaa uusi kuljetus</h1>

      <div className="flex flex-col-reverse sm:flex-row justify-start items-center mt-11 mb-8 space-y-4 space-y-reverse sm:space-y-0 space-x-0 sm:space-x-8">
        <Button
          className="w-full sm:w-auto"
          onClick={props.pressedConsumer}
        >
          Henkilöasiakkaat
        </Button>
        <Button
          className="w-full sm:w-auto"
          onClick={props.pressedBusiness}
        >
          Yritykset
        </Button>
      </div>
    </div>
  );
};
export default Start;
