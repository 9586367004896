import { useMemo } from "react";
import { Order } from "data/Order";
import SenderReceiverInfo from "./SenderReceiverInfo";
import fiLocale from "date-fns/locale/fi";
import { formatDate } from "data/Order";

type OrderDetailsProps = {
  order: Order;
  senderTitle: string;
  receiverTitle: string;
};

const OrderDetails = ({
  order,
  senderTitle,
  receiverTitle,
}: OrderDetailsProps) => {
  const now = useMemo(() => new Date(), []);

  return (
    <div className="flex flex-col space-y-6 md:grid md:grid-cols-2 md:gap-x-3 md:gap-y-9 md:space-y-0 mb-11">
      <div>
        <h2 className="snapi-title mb-4">{senderTitle}</h2>
        <SenderReceiverInfo {...order.sender} {...order.sender.address} />
      </div>
      <div>
        <h2 className="snapi-title mb-4">{receiverTitle}</h2>
        <SenderReceiverInfo {...order.receiver} {...order.receiver.address} />
      </div>

      <div>
        <h2 className="snapi-title mb-4">Toimituksen koko</h2>
        <p className="snapi-body">
          Pituus: {order.parcels[0].dimensions.length}cm
          <br />
          Leveys: {order.parcels[0].dimensions.width}cm <br />
          Korkeus: {order.parcels[0].dimensions.height}cm <br />
          Pakettien määrä: {order.parcelCount}<br />
          Paino: {order.parcels[0].weight}kg
        </p>
      </div>

      <div>
        <h2 className="snapi-title mb-4">Toimitus</h2>
        <p className="snapi-body">
          Noutoaika: {formatDate(now, order, fiLocale)}
          <br />
          <span>{order.price} € alv. 24%</span>
        </p>
      </div>

      {order.receiver.instructions.length > 0 && (
        <div>
          <h2 className="snapi-title mb-4">Viesti</h2>
          <p className="snapi-body">{order.receiver.instructions}</p>
        </div>
      )}
    </div>
  );
};

export default OrderDetails;
