import React from "react";
import { useForm, Controller } from "react-hook-form";
import Button from "components/Button";
import FormItem from "components/FormItem";
import { Address } from "data/Address";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import fiLocale from "react-phone-number-input/locale/fi.json";
import { OrderRoute } from "order/OrderRoute";
import { Link } from "react-router-dom";

type EnterAddressProps = {
  pressedBack: (result: Address) => void;
  pressedContinue: (result: Address) => void;
  backText: string;
  backVisible: boolean;
  title: string;
  defaultValue?: Address;
  showBusinessLink: boolean;
};

type Form = Pick<
  Address,
  "name" | "streetAddress" | "postCode" | "city" | "phone"
>;

const EnterAddress = (props: EnterAddressProps) => {
  const { register, handleSubmit, errors, control, getValues } = useForm<
    Address
  >({
    defaultValues: props.defaultValue,
  });

  const onSubmit = (data: Form) =>
    props.pressedContinue({ ...data, country: "FI" });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h1 className="snapi-title-lg mb-11">{props.title}</h1>
      <div className="flex flex-col space-y-6 sm:grid sm:grid-cols-2 sm:gap-x-3 sm:gap-y-6 sm:space-y-0">
        <FormItem
          name="name"
          label="Nimi"
          className="col-span-2"
          error={errors.name ? "Syötä kelvollinen nimi" : undefined}
          required={true}
          register={register}
        />

        <FormItem
          name="streetAddress"
          label="Osoite"
          className="col-span-2"
          error={errors.streetAddress ? "Syötä kelvollinen osoite" : undefined}
          required={true}
          register={register}
        />

        <FormItem
          name="postCode"
          label="Postinumero"
          className="col-span-1"
          error={errors.postCode ? "Syötä kelvollinen postinumero" : undefined}
          required={true}
          pattern={/^\d{5}$/}
          register={register}
        />

        <FormItem
          name="city"
          label="Postitoimipaikka"
          className="col-span-1"
          error={errors.city ? "Syötä kelvollinen postitoimipaikka" : undefined}
          required={true}
          register={register}
        />

        <div className="flex flex-col col-span-2">
          <label className="snapi-body" htmlFor="phone">
            Puhelinnumero
          </label>

          <Controller
            name="phone"
            control={control}
            defaultValue=""
            rules={{ required: true, validate: (p) => isValidPhoneNumber(p) }}
            render={(props) => (
              <PhoneInput
                id={props.name}
                name={props.name}
                className="snapi-input"
                defaultCountry="FI"
                value={props.value}
                onBlur={props.onBlur}
                onChange={props.onChange}
                labels={fiLocale}
              />
            )}
          />

          {errors.phone && (
            <span className="snapi-input-error mt-1">
              Syötä kelvollinen puhelinnumero
            </span>
          )}
        </div>
      </div>
      <div className="flex flex-col-reverse sm:flex-row justify-between items-center mt-11 mb-8 space-y-4 space-y-reverse sm:space-y-0 space-x-0 sm:space-x-8">
        {props.backVisible ? (
          <button
            onClick={() => {
              props.pressedBack(getValues());
            }}
            type="button"
            className="snapi-button-normal w-full sm:w-auto"
          >
            {props.backText}
          </button>
        ) : (
          <div />
        )}
        <Button className="w-full sm:w-auto">Jatka</Button>
      </div>
      {props.showBusinessLink && (
        <span className="snapi-body block">
          Oletko yritysasiakas?{" "}
          <Link className="snapi-link" to={OrderRoute.Business}>
            Siirry tänne
          </Link>
        </span>
      )}
      {props.showBusinessLink && (
        <div className="space-y-4 mt-11">
          <h3 className="snapi-title">SNÄPI</h3>
          <p className="snapi-body">
            Kun tilaat Snäpin, olemme noutamassa toimitusta saman tien.
            Viimeistään kahdessa kymmenessä minuutissa. Ja kun vientipaikka on
            20 km säteellä, ovat tavarat perillä jopa tunnissa tilauksestasi!
          </p>
          <h4 className="snapi-body font-bold">
            Toimitus vielä tänään illalla, tai heti aamulla aikaisin?
          </h4>
          <p className="snapi-body">
            Ei hätää. Toimimme 24/7! Toimitukset lähtevät juuri silloin kun
            tarvitset. Voit myös ajastaa kuljetuksen lähtemään parin tunnin
            päästä tai vaikka ensi viikolla.
          </p>
        </div>
      )}
      {props.showBusinessLink && (
        <a
          className="snapi-link block mt-4"
          href="https://snapi.fi/lisatiedot/kayttoehdot/"
        >
          Käyttöehdot
        </a>
      )}
    </form>
  );
};
export default EnterAddress;
